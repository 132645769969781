import type { HLocation } from '@reach/router';
import { graphql } from 'gatsby';
import * as React from 'react';

import { Layout } from '../components/layout';
import { SanityModules, TSanityModule } from '../components/sanity-modules';
import { SEO } from '../components/seo';
import type { ISeo } from '../types';

interface PageTemplateProps {
  data: {
    sanityPage: {
      modules: TSanityModule[];
      seo: ISeo;
    };
  };
  location: HLocation;
}

function PageTemplate({ data, location }: PageTemplateProps): JSX.Element {
  const { modules, seo } = data.sanityPage;
  const isBrowser = typeof window !== 'undefined';

  async function conversionsAPI() {
    let cookies = document.cookie.split(';');
    let fbp = 'none';
    console.log(cookies);

    cookies.map((cookie) => {
      if (cookie.includes('_fbp=')) {
        fbp = cookie.substr(cookie.indexOf('_fbp=') + 5);
        console.log(fbp);
      }
    });
    try {
      const res = await fetch('/.netlify/functions/conversions-api', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          eventType: 'Lead',
          fbp: fbp,
        }),
      });
      const result = await res.json();
      console.log('Return from netlify functions conversionsAPI =', result);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  React.useEffect(() => {
    if (seo.metaTitle === 'Success' && isBrowser && window.fbq) {
      // @ts-ignore
      window.fbq('track', 'Lead');
    }
    if (seo.metaTitle === 'Success' && isBrowser) {
      // @ts-ignore
      conversionsAPI();
    }
  }, []);

  return (
    <>
      <SEO
        title={seo?.metaTitle}
        image={seo?.shareGraphic?.asset.url}
        pathname={location.pathname}
      />
      <Layout>
        <SanityModules modules={modules} />
      </Layout>
    </>
  );
}

export const query = graphql`
  query PageTemplateQuery($slug__current: String!) {
    sanityPage(slug: { current: { eq: $slug__current } }) {
      modules {
        ... on SanityHero {
          ...SanityHeroParts
        }
        ... on SanityCopyWithImage {
          ...SanityCopyWithImageParts
        }
        ... on SanityServicesGrid {
          ...SanityServicesGridParts
        }
        ... on SanityHeadingWithCta {
          ...SanityHeadingWithCtaParts
        }
        ... on SanityFrequentlyAskedQuestions {
          ...SanityFrequentlyAskedQuestionsParts
        }
        ... on SanityContactForm {
          ...SanityContactFormParts
        }
        ... on SanityGoogleMap {
          ...SanityGoogleMapParts
        }
      }
      seo {
        metaDesc
        metaTitle
        shareDesc
        shareGraphic {
          asset {
            url
          }
        }
        shareTitle
      }
    }
  }
`;

export default PageTemplate;
